export const configConstants = [
  {
    type: "LLM",
    value: "llmCategory",
  },
  {
    type: "ERRORS",
    value: "errorsCategory",
  },
  {
    type: "MESSAGES",
    value: "messagesCategory",
  },
];
