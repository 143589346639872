import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  SourceEditing,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  Alignment,
  Link,
  MediaEmbed,
  BlockQuote,
  CodeBlock,
  List,
  Table,
} from "ckeditor5";
// import "ckeditor5.css";
import { useGlobalContext } from "context/globalContext";
import { ENDPOINT } from "constants/environment";

// const API_URL = `${ENDPOINT}/app/systemprompt`;
const API_URL = `${ENDPOINT}/app/v1/systemprompt`;

const FormPopup = ({
  isOpen,
  onClose,
  onSubmit,
  isEditMode,
  initialFormData,
}) => {
  const { getSystemPromptData } = useGlobalContext();
  const { formData, setFormData, initialFormState } = useGlobalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log(isSubmitting);

  useEffect(() => {
    if (isEditMode && initialFormData) {
      setFormData(initialFormData);
    } else {
      setFormData(initialFormState);
    }
  }, [isEditMode, initialFormData, initialFormState]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      value: data,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload = {
        ...formData,
        status: formData.status === "active" ? true : false,
        value: formData.value
          .split("\n")
          .map((each) => each.trimStart())
          .join("\n"),
      };

      // if (payload) return;
      const response = formData.id
        ? await axios.put(API_URL, payload)
        : await axios.post(API_URL, payload);

      const successMessage = formData.id
        ? "Prompt updated successfully"
        : "Prompt created successfully";
      toast.success(successMessage);

      onSubmit(response.data);
      onClose();
      setFormData(initialFormState);
      getSystemPromptData();
    } catch (error) {
      toast.error("Error creating/updating prompt. Please try again.");
      console.error("Error creating/updating prompt:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormState);
    onClose();
  };

  // const isFormValid = useMemo(() => {
  //   return (
  //     formData.key &&
  //     formData.value &&
  //     formData.status &&
  //     formData.comment &&
  //     formData.version
  //   );
  // }, [formData]);

  if (!isOpen) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <div className="z-50 mx-auto w-full max-w-fit rounded-xl bg-white p-6 shadow-xl sm:max-w-xl">
        <form onSubmit={handleSubmit}>
          <div className="flex max-h-[50vh] w-full  flex-col gap-4 overflow-y-auto sm:flex-row ">
            <div className="sm:w-[50%]">
              <div className="mb-4">
                <label className="mb-2 block font-bold text-gray-700">
                  Key:
                </label>
                <input
                  type="text"
                  name="key"
                  value={formData.key}
                  onChange={handleChange}
                  className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex-col justify-between">
                <div className="mb-4">
                  <label className="mb-2 block font-bold text-gray-700">
                    Version:
                  </label>
                  <input
                    type="text"
                    name="version"
                    value={formData.version}
                    onChange={handleChange}
                    className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  ></input>
                </div>
                <div className="mb-4">
                  <label className="mb-2 block font-bold text-gray-700">
                    Status:
                  </label>
                  <div className="flex items-center">
                    <div className="flex justify-between">
                      <label className="mr-4">
                        <input
                          type="radio"
                          name="status"
                          value="active"
                          checked={formData.status === "active"}
                          onChange={handleChange}
                          className="relative top-0.5 mr-1 cursor-pointer"
                        />
                        Active
                      </label>
                    </div>
                    <label>
                      <input
                        type="radio"
                        name="status"
                        value="inactive"
                        checked={formData.status === "inactive"}
                        onChange={handleChange}
                        className="relative top-0.5 mr-1 cursor-pointer"
                      />
                      In-Active
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label className="mb-2 block font-bold text-gray-700">
                  Comment:
                </label>
                <textarea
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                ></textarea>
              </div>
            </div>
            <div className="mb-4 sm:w-[50%]">
              <label className="mb-2 block font-bold text-gray-700">
                Value:
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.value}
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "|",
                      "sourceEditing",
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "subscript",
                      "superscript",
                      "|",
                      "alignment:left",
                      "alignment:center",
                      "alignment:right",
                      "alignment:justify",
                      "|",
                      "link",
                      "insertTable",
                      "mediaEmbed",
                      "blockQuote",
                      "codeBlock",
                      "|",
                      "bulletedList",
                      "numberedList",
                    ],
                  },
                  plugins: [
                    Bold,
                    Essentials,
                    Italic,
                    Mention,
                    Paragraph,
                    Undo,
                    SourceEditing,
                    Underline,
                    Strikethrough,
                    Subscript,
                    Superscript,
                    Alignment,
                    Link,
                    MediaEmbed,
                    BlockQuote,
                    CodeBlock,
                    List,
                    Table,
                  ],
                  // licenseKey: '<YOUR_LICENSE_KEY>',
                  // mention: {
                  //   // Mention configuration
                  // },
                  // initialData: "<p>Hello from CKEditor 5 in React!</p>",
                }}
                onChange={handleEditorChange}
              />
            </div>
          </div>

          <div className="mt-4 flex justify-between">
            <button
              type="submit"
              className={`rounded-md bg-green-600 py-2 px-4 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 `}
              // disabled={!isFormValid || isSubmitting}
            >
              {isEditMode ? "Update" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="rounded-md bg-red-600 py-2 px-4 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormPopup;
