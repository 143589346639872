import React, { useState } from "react";
import { BsPen, BsTrash } from "react-icons/bs";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import FormPopup from "FormModal/FormModal";
import { useGlobalContext } from "context/globalContext";
import toast from "react-hot-toast";
import axios from "axios";
import { ENDPOINT } from "constants/environment";

const Progress = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { setFormData, initialFormState, getSystemPromptData } =
    useGlobalContext();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const handleDeleteClick = () => {
  //   setIsModalOpen(true);
  // };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        // `${ENDPOINT}/app/systemprompt/?id=${props.cell.row.original.id}`
        `${ENDPOINT}/app/v1/systemprompt/?id=${props.cell.row.original.id}`
      );
      toast.success(`Prompt deleted successfully`);
      console.log("Delete successful:", response.data);
    } catch (error) {
      console.error("Error deleting the system prompt:", error);
    }
    console.log("Item deleted");
    setIsModalOpen(false);
    getSystemPromptData();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormOpen = () => {
    setIsFormOpen(true);

    setFormData(
      props.cell?.row?.original
        ? {
            ...props.cell.row.original,
            status: props.cell.row.original.status ? "active" : "inactive",
          }
        : {
            ...initialFormState,
            status: initialFormState.status ? "active" : "inactive",
          }
    );
    console.log(props, "props");
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  const handleFormSubmit = (formData) => {
    console.log("Form submitted:", formData);
  };

  return (
    <div className="flex gap-4">
      <BsPen
        className="cursor-pointer fill-amber-900"
        onClick={handleFormOpen}
      />
      {/* <BsTrash
        className="cursor-pointer fill-amber-900"
        onClick={handleDeleteClick}
      /> */}
      <DeleteModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onDelete={handleDeleteConfirm}
      />

      <FormPopup
        isOpen={isFormOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        isEditMode={true}
      />
    </div>
  );
};

export default Progress;
